<template>
	<div class="shadow-2 p-3 h-full flex flex-column surface-card" align="left" style="border-radius: 6px">
		<div>
			<Button  style="width: 100%;" @click="toggle_wechat_pay" title='click to view deal records 点击查看成交记录和评价' label="$230 Deal Records 成交记录"
				class="p-button-success mr-2 mb-2"></Button>
			<div class="flex align-items-center">
				<span class="ml-2 font-medium text-600" style="font-weight: 700;">The price will go up, if you need, buy now! 功能持续更新，价格不断上涨，早买早优惠！</span>
			</div>
			<OverlayPanel ref="wechat_pay" appendTo="body" :showCloseIcon="false">
				<div class="col-12">
					<!-- <div class="card"> -->
					<h5>Deal Records 成交记录</h5>
					<Carousel :value="deal_record_data" :numVisible="3" :numScroll="3" :circular="false"
						:responsiveOptions="carouselResponsiveOptions">
						<template #item="product">
							<div class="product-item">
								<div class="product-item-content">
									<div class="mb-3">
										<img :src="'images/dealrecords/' + product.data.image" :alt="product.data.name" width="600" />
									</div>
									<div>
										<h4 class="mb-1">
											{{ product.data.name }}
										</h4>
										<h6 class="mt-0 mb-3">{{ product.data.description }}</h6>
										<h4 class="mt-0 mb-3">{{ product.data.price }}</h4>
										<Rating :modelValue="product.data.rating" :readonly="true" :cancel="false"
											class="mb-2"></Rating>
									</div>
								</div>
							</div>
						</template>
					</Carousel>
					<!-- </div> -->
				</div>
			</OverlayPanel>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	import ProductService from "../service/ProductService";
	import PhotoService from "../service/PhotoService";
	export default {
		name: '',
		components: {

		},
		data() {
			return {
				pay_src: 'https://paypal.me/weichaoxu?country.x=C2&locale.x=zh_XC',
				carouselResponsiveOptions: [{
						breakpoint: "1024px",
						numVisible: 3,
						numScroll: 3,
					},
					{
						breakpoint: "768px",
						numVisible: 2,
						numScroll: 2,
					},
					{
						breakpoint: "560px",
						numVisible: 1,
						numScroll: 1,
					},
				],
				deal_record_data: [{
					"id": "1001",
					"code": "f230fh0g4",
					"name": "Pierpaolo* from Italy",
					"description": "Bought source code",
					"image": "Pierpaolo.jpg",
					"price": "$200",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1000",
					"code": "f230fh0g3",
					"name": "98 && is a rainbow from China",
					"description": "Bought encrypted code",
					"image": "98_is.jpg",
					"price": "￥66+66",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1002",
					"code": "f230fh0g5",
					"name": "Pranjul* from India",
					"description": "Product Description",
					"image": "Pranjul.jpg",
					"price": "$9.99",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}]
			}
		},
		created() {
			this.productService = new ProductService();
			this.photoService = new PhotoService();
		},
		mounted() {
			this.productService.getProductsSmall().then((products) => {
				this.products = products;
			});
			this.photoService.getImages().then((images) => {
				this.images = images;
			});
		},
		methods: {
			toggle_wechat_pay(event) {
				this.$refs.wechat_pay.toggle(event);
			},
		},
	}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
	@import '../assets/demo/items.scss';
</style>
